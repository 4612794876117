<template>
  <div>
    <b-card no-body class="mb-1">
      <div class="my-1 mx-1">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            md="4"
            sm="12"
            class="align-items-center justify-content-start mt-1"
          >
            <label class="pl-0" for="">&nbsp;</label>
            <excelExport
              header="KATILIM MİKTARLARI"
              :fetch="fetchData"
              sheetName="KATILIM MİKTARI"
              name="Tarihe göre katılımlar"
              :before-generate="startDownload"
              :before-finish="finishDownload"
            >
              <button class="btn btn-primary" style="width:120px;">
                <feather-icon icon="UploadIcon" /><span class="ml-1"
                  >Excel</span
                >
              </button>
            </excelExport>
          </b-col>
          <b-col
            md="4"
            sm="12"
            class="align-items-center justify-content-end mt-1"
          >
            <label class="pl-0" for="">{{ $t("Start Date") }}</label>
            <flat-pickr
              v-model="start_date"
              class="form-control"
              :config="configStart"
              @on-change="fpStartDateOnChange"
              :placeholder="placeholder"
            />
          </b-col>
          <b-col
            md="4"
            sm="12"
            class="align-items-center justify-content-end mt-1"
          >
            <label class="pl-0" for="">{{ $t("End Date") }}</label>
            <flat-pickr
              v-model="end_date"
              class="form-control"
              :config="configEnd"
              @on-change="fpEndDateOnChange"
              :placeholder="placeholder"
            />
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        ref="refUserListTable"
        class="text-left position-relative p-1"
        :bordered="true"
        striped
        :per-page="perPage"
        :current-page="currentPage"
        :items="attendanceByTimePeriodData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        filter-debounce="1000"
        show-empty
        :empty-text="$t('No Matching Creates Found')"
        :empty-filtered-text="$t('There are no records matching your request!')"
        :sort-desc.sync="isSortDirDesc"
      >
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <div class="d-flex align-items-right justify-content-right">
            <!-- <label>Show</label> -->
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </div>

          <b-col
            cols="12"
            sm=""
            class="d-flex align-items-right justify-content-right radius 0"
          >
            <small v-if="$i18n.locale === 'tr'" class="mt-1">
              içerisindeki {{ totalRows }} kayıttan {{ currentPage * 1 }}-{{
                currentPage * perPage
              }}
              arası ({{ currentPage }}.sayfa) gösteriliyor
            </small>
            <small v-if="$i18n.locale === 'en'" class="mt-1">
              showing {{ currentPage * 1 }}-{{ currentPage * perPage }} (page
              {{ currentPage }}) of {{ totalRows }} records</small
            >
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="6"
            sm=""
            class="  d-flex  align-items-right  justify-content-right justify-content-sm-end  radius  0 "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aling="right"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 r-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BFormSelect,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBreadcrumb,
  BBreadcrumbItem,
  VBPopover,
  BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import "flatpickr/dist/flatpickr.css";
import excelExport from "@/components/vue-json-excel/JsonExcel.vue";

export default {
  directives: {
    "b-popover": VBPopover,
    Ripple,
  },
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,
    vSelect,

    flatPickr,
    BFormRadio,
    excelExport,
  },

  data() {
    return {
      placeholder: "dd.mm.YYYY",
      currentPage: 1,
      totalRows: 1,
      isSortDirDesc: false,
      sortDesc: false,
      sortBy: "id",
      filter: null,
      perPageOptions: [10, 25, 50, 100],
      perPage: 25,
      selectedPeriod: "w",
      filterOn: [],
      locale: this.$i18n.locale,

      start_date: "12.12.2021",
      end_date: "12.12.2021",

      configStart: {
        dateFormat: "d.m.Y",
        locale: Turkish,
        minDate: "01.01.1940",
        maxDate: "today",
      },

      configEnd: {
        dateFormat: "d.m.Y",
        locale: Turkish,
        // maxDate: "today",
        minDate: "01.01.1940",
      },

      tableColumns: [],

      //  excel_fields: {
      //   'Musteri Adi':'Musteri Adi',
      //     '6.06.2022 12.06.2022' :'6.06.2022 12.06.2022',
      //     '30.05.2022 5.06.2022': '30.05.2022 5.06.2022',
      // },

      attendanceByTimePeriodData: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.totalRows = this.attendanceByTimePeriodData?.length;
  },
  methods: {
    fpStartDateOnChange(selectedDates, dateStr, instance) {
      this.tableColumns = []; //Tablo başlıklarını boşalt
      this.attendanceByTimePeriodData = [];
      this.configEnd.minDate = dateStr; //bitiş tarihininin başlangıç değeri en az başlangıç tarihi değerine eşit olabilir
      var prmStartDate = this.$functions.ConvertToEnglishDateFormat(dateStr);
      var prmEndDate = this.$functions.ConvertToEnglishDateFormat(
        this.end_date
      );
      //  console.log({"StartDate":prmStartDate},{"EndDate":prmEndDate})
      this.dataDoldur(
        this.companyBranchID,
        prmStartDate,
        prmEndDate,
        this.selectedPeriod
      );
    },

    fpEndDateOnChange(selectedDates, dateStr, instance) {
      this.tableColumns = []; //Tablo başlıklarını boşalt
      this.attendanceByTimePeriodData = [];
      this.configStart.maxDate = dateStr;
      var prmEndDate = this.$functions.ConvertToEnglishDateFormat(dateStr);
      var prmStartDate = this.$functions.ConvertToEnglishDateFormat(
        this.start_date
      );
      // console.log({"StartDate":prmStartDate},{"EndDate":prmEndDate})
      this.dataDoldur(
        this.companyBranchID,
        prmStartDate,
        prmEndDate,
        this.selectedPeriod
      );
    },
    async fetchData() {
      //   await  this.dataDoldur(this.companyBranchID, this.start_date,this.end_date)
      return await this.attendanceByTimePeriodData;
    },
    startDownload() {},
    finishDownload() {},
    dataDoldur(company_branch_id, startDate, endDate, selectedPeriod) {
      this.$database.ReportService.customer_activity_count(
        company_branch_id,
        startDate,
        endDate
      ).then((res) => {
        if (res.IsSuccess === 1 || res.IsSuccess === true) {
          const mydata = JSON.parse(res.Result);
          let keys = Object.keys(mydata[0]);
          keys.map((el) => {
            this.tableColumns.push({
              key: el,
              label: el == "CustomerName" ? this.$t("CustomerName") : el,
            });
          });
          mydata.map((el) => {
            this.attendanceByTimePeriodData.push(el);
          });
          this.totalRows = this.attendanceByTimePeriodData?.length;
        }
      });
    },
    splitPascalCase(word) {
      var wordRe = /($[a-z])|[A-Z][^A-Z]+/g;
      return word.match(wordRe).join(" ");
    },
    onFiltered(filteredItems) {
      console.log(this.filter);

      console.log(filteredItems.length);

      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  created() {
    this.companyBranchID = this.$store.getters[
      "auth/userInfo"
    ].company_branch_id;
    this.start_date = this.moment()
      .add(-1, "month")
      .locale("tr")
      .format("L");
    this.end_date = this.moment()
      .add(0, "month")
      .locale("tr")
      .format("L");
    var prmStartDate = this.$functions.ConvertToEnglishDateFormat(
      this.start_date
    );
    var prmEndDate = this.$functions.ConvertToEnglishDateFormat(this.end_date);
    this.selectedPeriod = "w";
    // console.log({"StartDate":prmStartDate},{"EndDate":prmEndDate})
    this.dataDoldur(
      this.companyBranchID,
      prmStartDate,
      prmEndDate,
      this.selectedPeriod
    );
  },
};
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/productListScoped.scss" scoped></style>
